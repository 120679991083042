import arrowUp from "../../assets/arrow-up.svg";

import "./_style.scss";

function ButtonTop() {
  const scrollFunction = () => {
    const mybutton = document.getElementById("btnTop");
    if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
        mybutton.style.display = "block";
    } 
    else {
        mybutton.style.display = "none";

    };
  };
  const handleClick = () => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
  };

  window.onscroll = () => scrollFunction();

  return (
    <button onClick={handleClick} id="btnTop" title="Отиди горе">
      <img load="lazy" src={arrowUp} alt="" />
    </button>
  );
}

export default ButtonTop;
