import { TAB_TITLE } from "../../utils/constants";
import comingSoon from "../../assets/coming-soon.jpg";
import "./style.scss";

function Portfolio() {
  document.title = new TAB_TITLE().ПОРТФОЛИО;

  return (
    <section className="portfolio">
      <img src={comingSoon} alt="" />
    </section>
  );
}

export default Portfolio;
