import React, { Suspense } from "react";
import { Routes, Route, Outlet } from "react-router-dom";

import "./App.scss";
import ButtonTop from "./components/ButtonTop";
import Portfolio from "./components/Portfolio";

const Intro = React.lazy(() => import("./components/Intro"));
const Navigation = React.lazy(() => import("./components/Navigation"));
const Contacts = React.lazy(() => import("./components/Contacts"));
const Services = React.lazy(() => import("./components/Services"));
const Loading = React.lazy(() => import("./components/Loading"));

function App() {
  return (
    <>
      <div className="root-container">
        <div className="container">
          <Navigation />
          <div className="container-bg">
            {/* Home */}
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Loading />}>
                    <Intro />
                    <Services />
                  </Suspense>
                }
              />
              {/* Contacts */}
              <Route
                path="/contacts"
                element={
                  <Suspense fallback={<Loading />}>
                    <Contacts />
                  </Suspense>
                }
              />
              {/* Portfolio */}
              <Route
                path="/portfolio"
                element={
                  <Suspense fallback={<Loading />}>
                    <Portfolio />
                  </Suspense>
                }
              />
            </Routes>
          </div>
        </div>
      </div>
      <Outlet />
      <ButtonTop />
    </>
  );
}

export default App;
